import React from 'react'
import Layout from '../components/layout'
import { FaSadTear } from 'react-icons/fa'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <h1>NIET GEVONDEN</h1>
    <p>Deze route bestaat helaas niet...</p>
	  <div class="sadFace center"><FaSadTear />
	  </div>
	  <div class="center">
		  <Link to="/">Terug naar start</Link>
	  </div>
  </Layout>
)

export default NotFoundPage
